<template>
  <div style="width: 100%; position: relative;overflow:hidden;">
    <div style="width: 100%; height: 1.306667rem"></div>
    <div class="MCaseBanner" style="padding-top: 3.16rem">
      <div class="bgImg">
        <img-item  imgName="编组 2 (4)" />
      </div>
      <div class="MBannerViewImg">
        <img-item  imgName="编组 8" />
      </div>
      
      <div class="MBannerLine" ></div>
      <div class="MBannerViewText">多创科技为您量身订做</div>
    </div>
    <display-module
      :titleInfo="titleInfo"
      style="margin-bottom: 1.066667rem; padding-top: 1.066667rem"
    />

    <div ref="case1">
      <div
        class="McaseItem flexEnd animate__animated animate__fadeInRight"
        v-show="case1Show"
      >
        <img-item  imgName="网约车2" />
        <div class="McaseItemBox">
          <div class="McaseItemNum">01</div>
          <div class="McaseItemTitle Medium">网约车平台</div>
          <div class="McaseItemText">
            携手首汽约车解决用户出行过程中“最后一公里”的痛点”，从根本上解决用户出行中遇到的实际困难，打造“铁路+网约车”一站式出行解决方案。
          </div>
        </div>
      </div>
      <div class="caseMainImg flexEnd animate__animated animate__fadeInRight" v-show="case1Show"> 
        <img-item  imgName="编组 5" />
      </div>
    </div>

    <div ref="case2">
      <div
        class="McaseItem flexStart animate__animated animate__fadeInLeft"
        v-show="case2Show"
      >
      <img-item  imgName="餐饮平台" />
        <div class="McaseItemBox">
          <div class="McaseItemNum">02</div>
          <div class="McaseItemTitle Medium">餐饮平台</div>
          <div class="McaseItemText">
            餐饮平台针对旅客多样化、个性化的旅行服务需求，引进社会品牌餐食，能够充分发挥“互联网+”优势，为旅客提供更多的选择、更好的服务。
          </div>
        </div>
      </div>
      <div class="caseMainImg flexEnd animate__animated animate__fadeInLeft" v-show="case2Show"> 
        <img-item  imgName="编组 4 (2)" />
      </div>
    </div>

    <div ref="case3">
      <div
        class="McaseItem flexEnd animate__animated animate__fadeInRight"
        v-show="case3Show"
      >
      <img-item  imgName="教育招生2" />
        <div class="McaseItemBox">
          <div class="McaseItemNum">03</div>
          <div class="McaseItemTitle Medium">
            泉港区义务教育招生信息管理平台
          </div>
          <div class="McaseItemText">
            本平台以服务泉港区义务教育招生工作为宗旨，利用现代信息科技手段，旨在于为广大考生和家长、学校、基层招办提供高质量和高效率服务。
          </div>
        </div>
      </div>
      <div class="caseMainImg flexEnd animate__animated animate__fadeInRight" v-show="case3Show"> 
        <img-item  imgName="截屏2020-12-17 下午3.30.24备份" />
      </div>
    </div>

    <div ref="case4">
      <div
        class="McaseItem flexStart animate__animated animate__fadeInLeft"
        v-show="case4Show"
      >
      <img-item  imgName="快乐积分2" />
        <div class="McaseItemBox">
          <div class="McaseItemNum">04</div>
          <div class="McaseItemTitle Medium">快乐学习积分系统</div>
          <div class="McaseItemText">
            快乐学习积分系统针对学生课堂行为优化的游戏化课堂管理工具。班级优化大师为每一位学生设定了专属卡通角色，通过加减分、随机抽选进行角色升级，配合游戏化的规则、界面及音效，激发学生的好胜心与创造力。数据可自动记录、归档和计算，亦可一键发送至家长端。
          </div>
        </div>
      </div>
      <div class="caseMainImg flexEnd animate__animated animate__fadeInLeft" v-show="case4Show"> 
        <img-item  imgName="编组 6" />
      </div>
    </div>

    <div class="MbottomBox">
      <img-item  imgName="rawpixel-714362-unsplash (1)" />
      <div class="Medium">更多产品案例</div>
      <div class="Medium">期待您与我们一起创造</div>
    </div>
  </div>
</template>

<script>
import DisplayModule from "@/components/MDisplayModule/index";
export default {
  name: "Mcase",
  components: {
    DisplayModule
  },
  props: {
    showFlag: false
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "case",
        CHSTitle: "经典案例",
        instructions:
          "多创科技精英团队，力求用最专业的技术和服务，为全国客户提供更精准高效的一站式互联网产品设计开发方案，为企业品牌赋能！"
      },
      case1Show: false,
      case2Show: false,
      case3Show: false,
      case4Show: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      let _this = this;
      let refArray = [
        { ref: "case1", show: "case1Show" },
        { ref: "case2", show: "case2Show" },
        { ref: "case3", show: "case3Show" },
        { ref: "case4", show: "case4Show" }
      ];
      refArray.forEach((r, i) => {
        _this[r.show] = _this.gdjz(r.ref, 20);
      });
    },
    gdjz(div, offset) {
      let dom = this.$refs[div];
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        return b + c > a;
      }
    }
  },
  created() {}
};
</script>
<style>
.MCaseBanner {
  height: 11.213333rem;
  width: 10rem;
  text-align: center;
  color: #fff;
  position: relative;
}
.MCaseBanner .bgImg{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.MCaseBanner .bgImg img{
  width: 100%;
  height: 100%;
}
.MCaseBanner .MBannerViewImg img{
  position: relative;
  width: 4.853333rem;
  height: 2.48rem;
}
.MBannerViewTitle {
  font-family: DIN-BlackItalic, DIN;
  font-size: 0.666667rem;
  line-height: 1.2rem;
  letter-spacing: 0.026667rem;
}
.MBannerLine {
  position: relative;
  width: 1.2rem;
  height: 0.106667rem;
  margin: 0.413333rem auto 0.306667rem;
  background: #2b7bee;
}
.MBannerViewText {
  position: relative;
  font-size: 0.4rem;
  line-height: 0.56rem;
  letter-spacing: 0.026667rem;
}
.McaseItem {
  width: 10rem;
  height: 8.946667rem;
  padding: 1.24rem 0.666667rem 0;
  display: flex;
  position: relative;
}
.flexStart {
  justify-content: flex-start;
}
.flexEnd {
  justify-content: flex-end;
}
.McaseItem img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}
.McaseItemBox {
  position: relative;
  width: 6.226667rem;
  color: #fff;
}
.McaseItemNum {
  font-size: 1.6rem;
  font-family: DIN-BlackItalic, DIN;
  line-height: 1.946667rem;
  letter-spacing: 0.12rem;
  opacity: 0.4;
}
.McaseItemTitle {
  font-size: 0.533333rem;
  line-height: 0.733333rem;
  margin-top: -0.65rem;
  margin-bottom: 0.52rem;
}
.McaseItemText {
  font-size: 0.32rem;
  line-height: 0.466667rem;
  letter-spacing: 0.026667rem;
}
.MbottomBox {
  width: 10rem;
  height: 5.333333rem;
  text-align: center;
  color: #fff;
  margin-top: 2rem;
  position: relative;
}

.MbottomBox img{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.MbottomBox .Medium {
  position: relative;
  font-size: 0.64rem;
  line-height: 0.866667rem;
  letter-spacing: 0.066667rem;
}

.MbottomBox .Medium:nth-child(2){
  padding-top: 1.9rem;
}
.caseMainImg img {
  margin-top: -4rem;
  width: 100%;
  left: 0;
}
</style>
