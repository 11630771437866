<template>
  <div class="MTitleInfoMain">
    <div class="MENGTitle Bold">{{ titleInfo.ENGTitle }}</div>
    <div class="MCHSTitle Medium animate__animated animate__fadeInUp">{{ titleInfo.CHSTitle }}</div>
    <div class="MInstructions animate__animated animate__fadeInUp">{{ titleInfo.instructions }}</div>
  </div>
</template>
<script>
export default {
  props: {
    titleInfo: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.MTitleInfoMain {
  width: 100%;
  text-align: center;
}
.MENGTitle {
  height: 1.093333rem;
  font-size: 0.64rem;
  line-height: .986667rem;
  font-weight: bold;
  background: linear-gradient(180deg, #333333 0%, #ffffff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  opacity: 0.25;
}
.MCHSTitle {
  font-size: .64rem;
  color: #333333;
  line-height: .866667rem;
  margin-top: -0.613333rem;
  margin-bottom: .213333rem;
  letter-spacing: .04rem;
}
.MInstructions {
  width: 8.666667rem;
  margin: 0 auto;
  font-size: .266667rem;
  font-weight: 400;
  color: #333333;
  line-height: .4rem;
  letter-spacing: .026667rem;
}
</style>
